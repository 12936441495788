import { Button, Collapse, Modal } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { AuthServiceApi } from "../../../../apis/auth.api";
import { ServicePanelKey, ServiceType } from "../../../../constants/app.enums";
import useGetClients from "../../../../hooks/useGetClients";
import { clientSitesState } from "../../../../states/clientSites";
import showNotification from "../../../common/notification";
import SVGIcons from "../../../icons/svgs";
import "./index.scss";
import SocialServicePanel from "./social-service";

const { Panel } = Collapse;
const ServicesForm = () => {
  const { t } = useTranslation();
  const { getClients } = useGetClients();
  const [activeKey, setActiveKey] = useState([]);
  const { clientSelected } = useRecoilValue(clientSitesState);
  const [state, setState] = useRecoilState(clientSitesState);

  const onClickEnable = (serviceType: ServiceType) => {
    Modal.confirm({
      title: t("clientSites.enableSocialTitle"),
      content: <span>{t("clientSites.enableSocialContent")}</span>,
      okText: t("clientSites.enableNow"),
      cancelText: t("common.cancel"),
      cancelButtonProps: { type: "text" },
      icon: "",
      className: "confirm-modal",
      centered: true,
      onOk: async () => {
        if (!clientSelected?.id) return;
        await AuthServiceApi.toggleService({
          isEnable: true,
          serviceType: serviceType,
          clientId: clientSelected.id,
        });
        setState({
          ...state,
          clientSelected: {
            ...clientSelected,
            ...(serviceType === ServiceType.Social && {
              isEnableSocial: true,
              socialEnabledDate: moment.utc().format(),
            }),
          },
        });
        getClients();
        showNotification(
          "success",
          t("clientSites.enableSocialSuccess", { id: clientSelected.id })
        );
      },
    });
  };

  const onChange = (value: any) => {
    setActiveKey(value);
  };
  useEffect(() => {
    setActiveKey([]);
  }, [clientSelected]);
  return (
    <div className="service-form">
      <p className="description">{t("clientSites.serviceDescription")}</p>

      <Collapse
        className="site-collapse-custom-collapse"
        bordered={false}
        expandIconPosition={"end"}
        activeKey={activeKey}
        onChange={onChange}
      >
        {!clientSelected?.isEnableSocial ? (
          <Panel
            header={
              <>
                <div className="title">
                  <SVGIcons.PolygonIcon /> {t("clientSites.social")}{" "}
                </div>
                <div className="description">
                  {t("clientSites.socialServicePanelDescription")}
                </div>
              </>
            }
            key={ServicePanelKey.SocialDisable}
            showArrow={false}
            extra={
              <Button onClick={() => onClickEnable(ServiceType.Social)} disabled={!clientSelected?.userPlan}>
                <SVGIcons.PowerIcon /> {t("common.enable")}{" "}
              </Button>
            }
            collapsible={"disabled"}
          ></Panel>
        ) : (
          <Panel
            header={
              <>
                <div className="title">
                  <SVGIcons.PolygonIcon /> {t("clientSites.social")}{" "}
                </div>
                <div className="description">
                  {t("clientSites.socialServicePanelDescription")}
                </div>
              </>
            }
            key={ServicePanelKey.Social}
          >
            <SocialServicePanel />
          </Panel>
        )}
      </Collapse>
    </div>
  );
};

export default ServicesForm;
