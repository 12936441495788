import { Card, Col, Divider, Row, Skeleton, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import SVGIcons from "../../../components/icons/svgs";
import { DATE_TIME_FORMAT } from "../../../constants/app-constants";
import { CycleType, PlanType, ServiceType, UserPlanStatus } from "../../../constants/app.enums";
import useClient from "../../../hooks/useClient";
import useClientPlan from "../../../hooks/useClientPlan";
import DateUtils from "../../../utils/date.utils";
import "./index.scss";
import { GetClientUsageResponse } from "../../../models/plan.model";
import { useRecoilValue } from "recoil";
import { accessServiceState } from "../../../states/social";

interface UsageProps {
    usage: GetClientUsageResponse | undefined;
    loading: boolean;
}

const Usage = (props: UsageProps) => {
    const { usage, loading } = props;
    const { t } = useTranslation();
    const { userPlan } = useClientPlan()
    const { timezoneId } = useClient();
    const renewTime = DateUtils.getDateWithTimezone(usage?.renewTime, timezoneId).format(DATE_TIME_FORMAT.viewFormat);
    const accessServices = useRecoilValue(accessServiceState);

    const getMaximumUsage = (usage: number | undefined) => {
        if (!userPlan) {
            return "—"
        }
        if (usage && usage < 0) {
            return <span className="usage__infinite">∞</span>
        }
        else {
            return usage
        }
    }

    const convertKBtoGB = (kb: number, hasToFixed?: boolean) => {
        if (!userPlan) {
            return "—"
        }
        if (kb && kb > 0) {
            const gigabytes = kb / 1024 / 1024;
            if (hasToFixed) {
                return gigabytes.toFixed(2);
            }
            return gigabytes
        }
        return 0;

    }

    const getPostsPerCycle = () => {
        let cycle = '';

        if (usage && usage.post) {
            switch (usage.post.cycleType) {
                case CycleType.Monthly:
                    cycle = 'per month'
                    break

                case CycleType.Weekly:
                    cycle = 'per week'
                    break

                default:
                    cycle = ''
                    break
            }
        }
        return { cycle }
    };


    const SkeletonLoading = () => {
        return <>
            <Skeleton.Avatar active size='large' shape='circle' />
            <Skeleton.Input active />
        </>
    }

    const getTotalPostUsed = () => {
        if (!userPlan) {
            return "—"
        }
        else {
            return usage?.post?.totalUsed
        }
    }

    const getTotalAiCreditUsed = () => {
        if (!userPlan) {
            return "—"
        }
        else {
            return usage?.aiCredit?.totalUsed;
        }
    }

    return (
        <div className="usage" >
            <Card >
                <div className="usage__heading">
                    {t("usageAndBilling.usage")}
                </div>
                <Divider className="usage__divider" />

                <Row gutter={[20, 20]} className="usage__wrapper" >
                    {accessServices.data.includes(ServiceType.Social) &&
                        <>
                            <Col lg={8} md={8} sm={24} xs={24}>
                                <div className="item" >
                                    {
                                        loading ? <SkeletonLoading /> : <>
                                            <div className="icon-color purple" >
                                                <SVGIcons.AiIcon />
                                            </div>
                                            <div  >
                                                <div className="title">{getTotalAiCreditUsed()}/{getMaximumUsage(usage?.aiCredit?.maximum)}</div>
                                                <div className="desc">
                                                    <span>{t("usageAndBilling.aiCredits")}</span> {(userPlan?.planType === PlanType.Free && userPlan?.status !== UserPlanStatus.Canceled) && <span>{t("usageAndBilling.refreshes", {
                                                        time: renewTime
                                                    })}</span>}
                                                </div>
                                            </div>
                                        </>
                                    }

                                </div>
                            </Col>

                            <Col lg={8} md={8} sm={24} xs={24}>
                                <div className="item" >
                                    {
                                        loading ? <SkeletonLoading /> : <>
                                            <div className={userPlan ? "icon-color blue" : "icon-color gray"}>
                                                <SVGIcons.NotesIcon />
                                            </div>
                                            <div  >
                                                <div className={userPlan ? "title" : "title gray"}>{getTotalPostUsed()}/{getMaximumUsage(usage?.post?.maximum)}</div>
                                                <div className="desc">{t("usageAndBilling.posts")}
                                                    {
                                                        userPlan && usage?.post?.maximum && usage?.post?.maximum > 0 ?
                                                            <Tooltip
                                                                overlayClassName="custom-tooltip"
                                                                title={
                                                                    t("usageAndBilling.maximumPosts", { posts: usage?.post.maximum, cycle: getPostsPerCycle().cycle })
                                                                }
                                                            >
                                                                <div className="circle-icon" ><SVGIcons.InfoCircleIcon /></div>
                                                            </Tooltip>
                                                            : <></>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </Col>
                        </>
                    }
                    <Col lg={8} md={8} sm={24} xs={24}>
                        <div className="item" >
                            {
                                loading ? <SkeletonLoading /> : <>
                                    <div className="icon-color light-green" >
                                        <SVGIcons.BuildingIcon />
                                    </div>
                                    <div  >
                                        <div className="title">{usage?.company?.totalUsed}/{getMaximumUsage(usage?.company?.maximum)}</div>
                                        <div className="desc">{t("usageAndBilling.companies")}</div>
                                    </div>
                                </>
                            }
                        </div>
                    </Col>
                    <Col lg={8} md={8} sm={24} xs={24}>
                        <div className="item" >
                            {
                                loading ? <SkeletonLoading /> : <>
                                    <div className="icon-color parakeet" >
                                        <SVGIcons.TeamMembersIcon />
                                    </div>
                                    <div  >
                                        <div className="title">{usage?.teamMember?.totalUsed}/{getMaximumUsage(usage?.teamMember?.maximum)}</div>
                                        <div className="desc">{t("usageAndBilling.teamMembers")}</div>
                                    </div>
                                </>
                            }

                        </div>
                    </Col>
                    <Col lg={8} md={8} sm={24} xs={24}>
                        <div className="item" >
                            {
                                loading ? <SkeletonLoading /> : <>
                                    <div className="icon-color steel" >
                                        <SVGIcons.ReportAnalyticsIcon />
                                    </div>
                                    <div  >
                                        <div className="title">{usage?.report?.totalUsed}/{getMaximumUsage(usage?.report?.maximum)}</div>
                                        <div className="desc">{t("usageAndBilling.analyticsReports")}</div>
                                    </div>
                                </>
                            }

                        </div>
                    </Col>
                    {
                        accessServices.data.includes(ServiceType.Social) &&
                        <Col lg={8} md={8} sm={24} xs={24}>
                            <div className="item" >
                                {
                                    loading ? <SkeletonLoading /> : <>
                                        <div className="icon-color storage" >
                                            <SVGIcons.PackageIcon />
                                        </div>
                                        <div>
                                            <div className="title">{convertKBtoGB(usage?.storage?.totalUsed || 0, true)}/{convertKBtoGB(usage?.storage?.maximum || 0)} {userPlan ? "GB" : ""}</div>
                                            <div className="desc">{t("usageAndBilling.storage")}</div>
                                        </div>
                                    </>
                                }

                            </div>
                        </Col>
                    }
                </Row>

            </Card>
        </div>
    );
};

export default Usage;
